<template>
  <sdPopover placement="bottomLeft" action="click">
    <template v-slot:content>
      <a @click="exportToExcel">
        <unicon name="x"></unicon>
        <span>Excel (XLSX)</span>
      </a>
      <a @click="exportToCSV">
        <unicon name="file"></unicon>
        <span>CSV</span>
      </a>
      <a to="#">
        <unicon name="book-open"></unicon>
        <span>PDF</span>
      </a>
      <a @click="printContent">
        <unicon name="print"></unicon>
        <span>Printer</span>
      </a>
    </template>
    <sdButton size="sm" type="white">
      <unicon name="import"></unicon>
      <span>Export</span>
    </sdButton>
  </sdPopover>
</template>

<script setup>
import { saveAs } from "file-saver"; // Import FileSaver.js
import { defineProps } from "vue";
const props = defineProps({
  rows: Object,
  columns: Array,
});
// console.log(props.rows, props.columns);
const generateExcelData = (rows, columns) => {
  const headerRow = columns.map((col) => col.title);
  const dataRows = rows.map((row) => {
    const rowData = [];
    for (const column of columns) {
      if (Array.isArray(column.dataIndex)) {
        let nestedValue = row;
        for (const dataIndex of column.dataIndex) {
          if (nestedValue === null) break; // Handle null values
          nestedValue = nestedValue[dataIndex];
        }
        rowData.push(nestedValue);
      } else {
        rowData.push(row[column.dataIndex]);
      }
    }
    return rowData;
  });

  return [headerRow, ...dataRows];
};

const generateCSVData = (rows, columns) => {
  const headerRow = columns.map((col) => col.title).join(",");

  const dataRows = rows.map((row) => {
    const rowData = [];
    for (const column of columns) {
      if (Array.isArray(column.dataIndex)) {
        let nestedValue = row;
        for (const dataIndex of column.dataIndex) {
          if (nestedValue === null) break; // Handle null values
          nestedValue = nestedValue[dataIndex];
        }
        rowData.push(nestedValue);
      } else {
        rowData.push(row[column.dataIndex]);
      }
    }
    return rowData.join(",");
  });

  return [headerRow, ...dataRows].join("\n");
};
const convertToWorkbook = (data) => {
  return data;
};
const saveWorkbookAsFile = (workbook, filename) => {
  try {
    const workbookBlob = new Blob([workbook], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(workbookBlob, filename);
  } catch (error) {
    console.error("Error saving the file:", error);
  }
};
const saveTextAsFile = (text, filename) => {
  const blob = new Blob([text], { type: "text/csv;charset=utf-8" });
  saveAs(blob, filename);
};
// eslint-disable-next-line no-unused-vars
const exportToExcel = () => {
  const excelData = generateExcelData(props.rows, props.columns);
  const workbook = convertToWorkbook(excelData);
  saveWorkbookAsFile(workbook, "exported_data.xlsx");
};
// eslint-disable-next-line no-unused-vars
const exportToCSV = () => {
  const csvData = generateCSVData(props.rows, props.columns);
  saveTextAsFile(csvData, "exported_data.csv");
};
// eslint-disable-next-line no-unused-vars
const printContent = () => {
  window.print();
};
</script>
